<template>
  <div class="pageMain" :class="globalDataArray.siteInfo.greyOut == 1 ? 'bigEventGray' : ''">
    <div class="banner perBan">
      <img :src="banners.image" alt="">
      <div class="w1440">
        <div class="txt">
          <p class="fnt_52">
            {{ globalDataArray.siteTemplateLanguage.banner_welcome }}
            <template v-if="saitiAccessInfo.username!=''&&saitiAccessInfo.username!=null">
              {{ saitiAccessInfo.username }}
            </template>
            <template v-else-if="saitiAccessInfo.nickname!=''&&saitiAccessInfo.nickname!=null">
              {{ saitiAccessInfo.nickname }}
            </template>
          </p>
          <span class="fnt_18 mail" v-if="saitiAccessInfo.email!=''&&saitiAccessInfo.email!=null">
            {{ saitiAccessInfo.email }}
          </span>
          <span class="fnt_18" v-else-if="saitiAccessInfo.phone!=''&&saitiAccessInfo.phone!=null">
            {{ saitiAccessInfo.phone }}
          </span>
        </div>
      </div>
    </div>

    <div class="personPage">
      <div class="cf w1440">
        <div class="personLeft">
          <personmenu />
        </div>

        <div class="personRight">
          <div class="tit fnt_32">{{ globalDataArray.siteTemplateLanguage.person_message_record }}</div>

          <div class="antDesignTable">
            <a-table
              row-key="id"
              :scroll="{x:true}"
              :columns="columns"
              :data-source="list"
              :pagination="paginationOpt"
              class="j-table-force-nowrap"
            >
              <template slot="tit" slot-scope="text, record">
                <span @click="seeInfo(record)" class="compNameBtn relative">{{ text }} <span v-if="record.readFlag=='0'" class="mymsgdot"></span></span>
              </template>

              <template slot="action" slot-scope="text, record">
                <span @click="delMsgFun(record.id)" class="compExamineBtn">{{ globalDataArray.siteTemplateLanguage.delete }}</span>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>

    <personmsg :personmsg.sync="personmsg" :curid.sync="curid" />
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import Cookies from 'js-cookie'
import { getAdList } from '@/api/api'
import { MessageRecordList, deleteMessageRecord } from '@/api/person/person'
export default {
  name: 'Myapply',
  data() {
    return {
      title: this.globalDataArray.siteInfo.title,
      description: this.globalDataArray.siteInfo.description,
      keywords: this.globalDataArray.siteInfo.keywords,

      paginationOpt: {
        current: 1,
        defaultCurrent: 1,
        defaultPageSize: 10,
        pageSize: 10,
        total: 0,
        hideOnSinglePage: true,
        onChange: (current, size) => {
          this.defaultPageSize = size
          this.paginationOpt.current = current
          this.MessageRecordList()
        },
        // onChange:在分页数量和当前页发生变化时触发
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.pageSize = pageSize
        }
      },
      saitiAccessInfo: '',

      columns: [
        {
          title: '序号',
          dataIndex: '',
          key: 'rowIndex',
          width: 80,
          align: 'center',
          customRender: (text, record, index) => {
            return ((this.paginationOpt.current - 1) * this.paginationOpt.pageSize + index + 1)
          }
        },
        {
          title: '消息发布者',
          align: 'center',
          dataIndex: 'sender'
        },
        {
          title: '标题',
          align: 'center',
          dataIndex: 'title',
          scopedSlots: { customRender: 'tit' }
        },
        {
          title: '发布时间',
          align: 'center',
          dataIndex: 'createTime'
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],

      list: [],

      personmsg: false,
      curid: {},
      banners: {}
    }
  },
  created() {
    this.init()
  },
  mounted() {
    if (Cookies.get('saiti_access_info') != '' && Cookies.get('saiti_access_info') != undefined && Cookies.get('saiti_access_info') != null) {
      this.saitiAccessInfo = JSON.parse(Cookies.get('saiti_access_info'))
    }
  },
  methods: {
    init() {
      this.getAdList()
      this.tableHeader()
      this.MessageRecordList()
      if (Cookies.get('saiti_access_info') != '' && Cookies.get('saiti_access_info') != undefined && Cookies.get('saiti_access_info') != null) {
        this.saitiAccessInfo = JSON.parse(Cookies.get('saiti_access_info'))
      }
      // this.seeInfo();
    },
    tableHeader() {
      const that = this
      that.columns[0].title = that.globalDataArray.siteTemplateLanguage.person_table_number
      that.columns[1].title = that.globalDataArray.siteTemplateLanguage.msg_publisher
      that.columns[2].title = that.globalDataArray.siteTemplateLanguage.message_title
      // that.columns[3].title = that.globalDataArray.siteTemplateLanguage.person_table_competition_title
      that.columns[3].title = that.globalDataArray.siteTemplateLanguage.publish_time
      that.columns[4].title = that.globalDataArray.siteTemplateLanguage.person_table_controls
    },
    MessageRecordList() {
      const that = this
      const param = 'page=' + that.paginationOpt.current + '&pageSize=' + that.paginationOpt.defaultPageSize
      MessageRecordList(param).then(res => {
        if (res.success) {
          that.list = res.result.records
          that.paginationOpt.total = res.result.total
        }
      })
    },
    getAdList() {
      const that = this
      const adParam = 'alias=person_banner'
      getAdList(adParam).then(res => {
        if (res.success) {
          that.banners = res.result[0]
        }
      })
    },
    seeInfo(record) {
      const that = this
      that.personmsg = true
      that.curid = record

      that.list.forEach((item, index) => {
        if (item.id == record.id) {
          if (item.readFlag == '0') {
            const msgnum = this.$store.state.msgnum - 1
            this.$store.commit('setSiteMsgnum', msgnum)
          }
          item.readFlag = '1'
        }
      })
    },

    delMsgFun(id) {
      // console.log(id)
      const that = this
      Modal.confirm({
        title: that.globalDataArray.siteTemplateLanguage.person_delete_message,
        content: '',
        okText: that.globalDataArray.siteTemplateLanguage.verify,
        cancelText: that.globalDataArray.siteTemplateLanguage.cancel,
        onOk() {
          const param = 'id=' + id
          deleteMessageRecord(param).then(res => {
            if (res.success) {
              that.MessageRecordList()
            }
          })
        },
        onCancel() {
          // console.log('Cancel');
        }
      })
    }
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { vmid: 'description', name: 'description', content: this.description },
        { vmid: 'keywords', name: 'keywords', content: this.keywords }
      ]
    }
  }
}
</script>
<style>
@import '@/assets/css/person.css'
</style>
